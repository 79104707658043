<template>
  <div class="mt-24">
    <div class="w-full max-w-6xl px-6 mx-auto lg:px-0">
      <h1
        class="mt-5 text-3xl font-extrabold text-center text-gray-700 font-heading-sans"
      >Roofone Terms of Service</h1>
      <p class="mt-2 text-center text-gray-600">Last Updated: May 09, 2020</p>
      <p class="mt-5 leading-relaxed text-gray-500">
        These terms define all the legal rules, rights and obligations
        applying to your use of this site and its operations. By using our site,
        you confirm that you have familiarized yourself with, you have fully accepted these
        terms and you have agreed to abide thereby. If you do not agree to these terms,
        please stop using our site.These terms are a legally binding agreement
        (as public offer) between you and us.
      </p>
      <h3 class="text-xl text-gray-700 mt-7 font-heading-sans">Personal Information</h3>

      <div class="mt-3 leading-relaxed text-gray-500">
        <p>
          Throughout this policy, the term “Personal Information”
          describes information that can be associated with a specific person and
          can be used to identify that person. We do not consider personal
          information to include anonymous information that does not refer to a
          specific user. We may collect and process the following types of
          Personal Information in order to provide you with access to our
          website:
        </p>

        <ul class="mt-3 ml-4 list-disc">
          <li>
            personal data such as name, age, email address, phone number,
            physical contact information, personal description, photograph,
            username, password, other registration information and (depending on
            the service used) sometimes financial information, such as Bank
            Verification Number, credit card or bank account numbers.
          </li>
          <li class="mt-3">
            transactional information based on your activities on the website
            (such as products or service purchased, content you generate or that
            relates to your account), billing, and other information you may have
            provided in connection with your orders.
          </li>
          <li class="mt-3">
            personal information you provide to us through information pages,
            correspondence, chats, complaints, customer service channels, or
            provided to us from other social applications or services.
          </li>
          <li class="mt-3">
            additional personal information we ask you to submit as part of our
            verification process (for example, we may ask you to send us an
            identification document or utility bill to verify your address, or to
            answer additional questions online to help us verify your identity).
          </li>
          <li class="mt-3">
            other supplemental information obtained from third parties such as
            demographic and navigation data, credit check information, and
            additional information about you from a credit bureau, as permitted
            by law.
          </li>
          <li class="mt-3">
            information from your interaction with our website, services, content
            and advertising, including, but not limited to, device ID, device
            type, unique device identifiers; geo-location information, computer,
            operating system and connection information, statistics on page
            views, IP address and standard web log information.
          </li>
          <li class="mt-3">
            information stored on your device, including contact lists, call
            logs, SMS logs, Facebook friends, contact lists from other social
            media accounts, photos, videos or other digital content.
          </li>
          <li class="mt-3">
            details of any of your visits to the website; including, but not
            limited to, traffic data, location data, web logs and
            other communication data.
          </li>
        </ul>
      </div>
      <div class="mt-5 leading-relaxed text-gray-500">
        <p>This information may be provided or collected by:</p>

        <ul class="mt-3 ml-4 list-disc">
          <li>filling in forms on the Website</li>
          <li class="mt-3">
            transactional information based on your activities on the website
            (such as products or service purchased, content you generate or that
            relates to your account), billing, and other information you may have
            provided in connection with your orders.
          </li>
          <li class="mt-3">
            personal information you provide to us through information pages,
            correspondence, chats, complaints, customer service channels, or
            provided to us from other social applications or services.
          </li>
          <li class="mt-3">
            additional personal information we ask you to submit as part of our
            verification process (for example, we may ask you to send us an
            identification document or utility bill to verify your address, or to
            answer additional questions online to help us verify your identity).
          </li>
        </ul>
      </div>

      <h3 class="text-xl text-gray-700 mt-7 font-heading-sans">Location Information</h3>

      <div class="mt-3 leading-relaxed text-gray-500">
        <p>
          We may also use GPS technology OR other location services to determine
          your current location. You can withdraw your consent to our collection,
          processing or use of this information at anytime by logging out of the
          website.
        </p>
      </div>

      <h3 class="text-xl text-gray-700 mt-7 font-heading-sans">Third Party Information</h3>

      <div class="mt-3 leading-relaxed text-gray-500">
        <p>
          Except as otherwise expressly included in this Policy, this
          Policy addresses only the use and disclosure of information we collect
          from you. Due to the nature of our services, we may be required to
          work with a number of third parties (including credit reference
          agencies and mobile network providers) and we may receive information
          about you from them. If you disclose your information to other uses of
          our Website or other sites throughout the internet, different rules
          may apply to their use or disclosure of the information you provide
          to them. Roofone does not control the privacy policy of third
          parties and will not be liable for any illegal use of your personal
          information by such third parties. You are, however, subject to the
          privacy policies of those third parties where applicable. We encourage
          you to ask questions before you disclose your Personal Information to
          others.
        </p>
      </div>

      <h3 class="text-xl text-gray-700 mt-7 font-heading-sans">Use of Information</h3>

      <div class="mt-3 leading-relaxed text-gray-500">
        <p>You agree that we may use your personal information to</p>
        <ul class="mt-3 ml-4 list-disc">
          <li>
            provide access to the Website and the services and customer support
            you request
          </li>
          <li class="mt-3">resolve disputes, collect fees, and troubleshoot problems</li>
          <li class="mt-3">
            prevent, detect, and investigate potentially prohibited or illegal
            activities
          </li>
          <li class="mt-3">
            customize, measure, and improve our services, content,and
            advertising
          </li>
          <li class="mt-3">
            tell you about our services and deliver targeted marketing, service
            updates, and promotional offers based on your communication
            preferences; and
          </li>
          <li class="mt-3">compare information for accuracy, and verify it with third parties</li>
          <li class="mt-3">
            contact you using the telephone numbers you have provided provide
            you other services requested by you as described when we collect the
            information
          </li>
        </ul>
      </div>

      <h3 class="text-xl text-gray-700 mt-7 font-heading-sans">Disclosure of Information</h3>

      <div class="mt-3 leading-relaxed text-gray-500">
        <p>
          We may disclose personal information in order to respond to legal or
          regulatory requests, enforce our policies, or protect anyone's
          rights, property, or safety. Such information will be disclosed in
          accordance with applicable laws and regulations.
          <br />We may also disclose your personal information:
        </p>
        <ul class="mt-3 ml-4 list-disc">
          <li>
            provide access to the Website and the services and customer support
            you request
          </li>
          <li class="mt-3">resolve disputes, collect fees, and troubleshoot problems</li>
          <li class="mt-3">
            prevent, detect, and investigate potentially prohibited or illegal
            activities
          </li>
          <li class="mt-3">
            customize, measure, and improve our services, content, and
            advertising
          </li>
          <li class="mt-3">
            tell you about our services and deliver targeted marketing, service
            updates, and promotional offers based on your communication
            preferences; and
          </li>
          <li class="mt-3">compare information for accuracy, and verify it with third parties</li>
          <li class="mt-3">
            contact you using the telephone numbers you have provided to provide
            you other services requested by you as described when we collect the
            information.
          </li>
        </ul>
        <p class="mt-3">
          Save in compliance with an order of a Court, an Arbitral Panel, a
          Tribunal, an investigatory panel, or any other legal or regulatory
          obligation, we do not disclose information about identifiable
          individuals to other parties, but we may provide them with anonymous or
          aggregate information about our users.
        </p>
      </div>

      <h3 class="text-xl text-gray-700 mt-7 font-heading-sans">Storage of Information</h3>

      <div class="mt-3 leading-relaxed text-gray-500">
        <p>
          The data that we collect from you may be transferred to, and stored at,
          a destination outside Nigeria. It may also be processed by
          staff operating outside Nigeria, who work for us or for one of
          our related entities. By submitting your personal data, you agree to the
          collection,transfer, storage or processing of your personal data in
          the manner set out above. We will take all steps reasonably necessary
          to ensure that your data is treated, stored and processed securely and
          inaccordance with this Policy.
          <br />
          <br />Where we have given you (or where you have chosen) a password
          that enables you to access certain parts of the Website, you
          are responsible for keeping this password confidential. Use
          unique numbers, letters and special characters for your password. We
          ask you not to share a password with anyone. If you do share
          your password or your personal information with others, remember
          that you are responsible for all actions taken in the name of your
          account. If you lose control of your password, you may lose substantial
          control over your personal information and may be subject to legally
          binding actions taken on your behalf.
          <br />
          <br />Unfortunately, the transmission of information via the internet
          is not completely secure. Although we will do our best to protect
          your personal data, we cannot guarantee the security of your
          data transmitted through the Website; any transmission is at your own
          risk.Once we have received your information, we will use strict
          procedures and security features to try to prevent unauthorised
          access.
          <br />
          <br />We expect that the personal information that you
          provide to us is accurate and up to date, and we will provide you with
          the opportunity to update your information through your account profile
          settings. We retain information as long as it is necessary and relevant
          for our operations. In addition, we may retain personal information
          from closed accounts to comply with the law, prevent fraud, collect any
          fees owed, resolve disputes, troubleshoot problems, assist with
          any investigation and take other actions permitted by law.
        </p>
      </div>

      <h3 class="text-xl text-gray-700 mt-7 font-heading-sans">Broker in Real Estate Transactions</h3>

      <div class="mt-3 leading-relaxed text-gray-500">
        <p>
          Roofone Capital Limited connects users together and reserves the right
          to act as a ‘Broker’ in respect of some transactions. Roofone Capital
          Limited can charge a marketplace/platform fee for use of the platform
          and also 4% professional fee (which covers legal, search and broker
          fee).
        </p>
      </div>

      <h3 class="text-xl text-gray-700 mt-7 font-heading-sans">Account Opening</h3>

      <div class="mt-3 leading-relaxed text-gray-500">
        <p>
          Users agree to create an account with Roofone Capital Limited
          and provide certain accurate information such as Full Name,
          Email address and Phone Number. This account can be created via
          Roofone Capital Limited's website or a correspondent of Roofone
          Capital Limited. This account is to enable us to serve users better and
          send users constant updates on their investments and market trends.
          When registering, you will be asked to create a password and will
          be responsible for maintaining the confidentiality of your password
          and restricting access to your computer, as you will be accountable for
          any activities conducted under your password. If you believe that
          someone has accessed your account without authorization, please contact
          us immediately.
        </p>
      </div>

      <h3 class="text-xl text-gray-700 mt-7 font-heading-sans">Use of Data - Disclaimer</h3>

      <div class="mt-3 leading-relaxed text-gray-500">
        <p>
          No assurance is given to Buyers regarding the accuracy, appropriateness
          of data, estimates, forecasts and projections displayed on the Roofone
          Site. No Assurance can equally be given regarding the accuracy or
          appropriateness of the assumptions and judgements made, or the
          methodologies used and such estimates, forecasts and projections are
          forward-looking statements and involve risks and uncertainties that may
          cause outcome of results to be different from the estimates, forecasts
          or projections. Therefore, buyers should rely on such estimates,
          projections and forecasts at its own risks.
        </p>
      </div>

      <h3 class="text-xl text-gray-700 mt-7 font-heading-sans">Liability and Indemnity</h3>

      <div class="mt-3 leading-relaxed text-gray-500">
        <p>
          Roofone Capital Limited shall not in any way whatsoever be held liable
          to indemnify or cause to be indemnified against damages or breach of
          contract arising from any transactions. Any legal remedy or liability
          in respect of Listings or any Purchase and Sale Agreement shall lie
          against the Party who occasions the damage or Breach or third party
          thereof. Such Liability or legal remedy shall be limited to aclaim
          against the Party or Third Party who directly caused the damage or
          breach thereof.
        </p>
      </div>

      <h3 class="text-xl text-gray-700 mt-7 font-heading-sans">Escrow</h3>

      <div class="mt-3 leading-relaxed text-gray-500">
        <p>
          Users are subjected to the use of Roofone Capital Limited Escrow
          for all transactions as stated in the Roofone Escrow Terms
          and Conditions. Seller will only be paid when full payment has been
          made by the Buyer or on the consensus of both parties. Default from
          the Buyer will result in the payment of an inconvenience fee of 2%
          the property value.
        </p>
      </div>

      <h3 class="text-xl text-gray-700 mt-7 font-heading-sans">Listings Verification</h3>

      <div class="mt-3 leading-relaxed text-gray-500">
        <p>
          Sellers are encouraged to post listings on Roofone Capital Limited
          Website. However, Roofone Capital Limited does not endorse
          such Listings and such Listings is subject to verification by Roofone
          Capital Limited. Roofone Capital Limited reserves the right to remove
          any illegal listing.
        </p>
      </div>

      <h3 class="text-xl text-gray-700 mt-7 font-heading-sans">Third-Party Information</h3>

      <div class="mt-3 leading-relaxed text-gray-500">
        <p>
          Except as otherwise expressly included in this Terms and
          Conditions,this Policy addresses only the use and disclosure of
          information we collect from you. Due to the nature of our services, we
          may be required to work with a number of third parties (including
          credit reference agencies and mobile network providers) and we
          may receive information about you from them. If you disclose
          your information to other uses of our Website or other sites throughout
          the internet, different rules may apply to their use or disclosure of
          the information you provide to them. Roofone Capital Limited does
          not control the privacy policy of third parties and will not be liable
          for any illegal use of your personal information by such third parties.
          You are, however, subject to the privacy policies of those third
          parties where applicable.
        </p>
      </div>

      <h3 class="text-xl text-gray-700 mt-7 font-heading-sans">Intellectual Property</h3>

      <div class="mt-3 leading-relaxed text-gray-500">
        <p>
          Copyright and all other intellectual property rights in this
          Site (including all database rights, trademarks, service marks,
          tradingnames, text, graphics, code, files and links) belong to us. All
          rights are reserved. Any unauthorized use shall be deemed as
          infringement and may be persecuted by law. Some of the materials on this
          Site may contain links to third-party websites and resources or include
          third party content, which we use under fair-use policy. We do not take
          responsibility and shall not be liable in any form for any event of
          third-party copyright infringement or breach.
        </p>
      </div>

      <h3 class="text-xl text-gray-700 mt-7 font-heading-sans">Cookies and Beacons</h3>

      <div class="mt-3 leading-relaxed text-gray-500">
        <p>
          Cookies are bits of electronic information that can be transferred
          to your computer or other electronic device to uniquely identify
          your browser. When you use the Services, we may place one or
          more cookies on your computer or other electronic device. We may
          use cookies to connect your activity on the Services with
          other information we store about you in your account profile or your
          prior interactions on the Services to, for example, store your
          preferences. The use of cookies helps us improve the quality of the
          Services to you,by identifying information which is most interesting
          to you, tracking trends, measuring the effectiveness of advertising, or
          storing information you may want to retrieve on a regular basis, such
          as your favorite homes. At any time, you may adjust settings on your
          browser to refuse cookies according to the instructions related to
          your browser. However, if you choose to disable cookies, many of the
          free features and Services will not operate properly. Also, the pages
          on the Services may include Web beacons or pixels, which are electronic
          files to count users who have visited that page, to track activity over
          time and across different Web sites, to identify certain cookies on
          the computer or other electronic device accessing that page, or to
          collect other related information, and this information may be
          associated with your unique browser, device identifier, or Internet
          Protocol address. We may, for example, implement a pixel on the pages
          of the Services where you view a certain advertisement so that we can
          track whether you visit a Web site associated with that advertisement
          at a later time.
        </p>
      </div>

      <h3 class="text-xl text-gray-700 mt-7 font-heading-sans">Indemnity</h3>

      <div class="mt-3 leading-relaxed text-gray-500">
        <p>
          Save in respect of liability for death or personal injury arising out
          ofnegligence or for fraudulent misrepresentation and subject
          toapplicable law, Roofone Capital Limited and its Officers,
          Directors, Employees, Shareholders and agents hereby disclaim to the
          fullest extent permitted by law all liability for any loss or damage
          including any consequential or indirect loss or damage incurred by
          you, whether arising in tort, contract or otherwise, and arising out of
          or in relation to or in connection with your access to or use of or
          inability to use this website.
          <br />
          <br />You agree to indemnify, defend and hold us and our
          affiliated companies, shareholders, officers, directors, employees,
          agents or suppliers harmless from any and all claims or demands, made
          by any third party due to or arising out of your use of the Services,
          the violation of these Terms and Conditions by you, or the infringement
          by you of any intellectual property or other right of any other person
          or entity.
        </p>
      </div>

      <h3 class="text-xl text-gray-700 mt-7 font-heading-sans">Applicable Law</h3>

      <div class="mt-3 leading-relaxed text-gray-500">
        <p>
          These Terms and conditions shall be governed by and construed
          in accordance with Nigerian law and the parties agree that any
          disputes will be settled in Nigerian courts. Should any provision of
          these Terms and Conditions be determined to be invalid or unenforceable
          by any court having competent jurisdiction, then the invalid or
          unenforceable provision will be replaced with a provision that reflects
          the intent of the original provision, to the extent permitted by
          applicable law and all other provisions of these Terms and Conditions
          shall remain in full force and effect
        </p>
      </div>

      <h3 class="text-xl text-gray-700 mt-7 font-heading-sans">General</h3>

      <div class="mt-3 leading-relaxed text-gray-500">
        <p>
          The headings in these Terms and Conditions are solely used
          for convenience and have no legal or contractual significance. We
          may assign this Agreement, in whole or in part, at any time without
          notice to you and upon such assignment will be relieved of any
          further obligation under these Terms and Conditions. You may not
          assign, transfer or sublicense your rights, if any, under these Terms
          and Conditions. Our delay or failure to exercise or enforce any right
          or provision of these Terms and Conditions with respect to a breach by
          you or others shall not constitute or be construed as a waiver of
          such right to act. We shall not be responsible for any breach of these
          Terms and Conditions caused by circumstances beyond our control.These
          Terms and Conditions together with the Privacy Policy constitute the
          entire agreement between you and us with respect to the Services and
          supersede any and all prior agreements and understandings between you
          and us.
        </p>
      </div>

      <h3 class="text-xl text-gray-700 mt-7 font-heading-sans">Contact Us</h3>

      <div class="mt-3 mb-10 leading-relaxed text-gray-500">
        <p>
          If you have any questions about these Terms and Conditions, please
          contact us:
        </p>
        <ul class="mt-3 ml-4 list-disc">
          <li>By Email: info@roofonecapital.com</li>
          <li class="mt-3">By Phone: +234 907 6776 758</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
